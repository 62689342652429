<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain game-pcdddw pcddopen ">
        <!-- 数字-->
        <div   :class="'data cZ'+(i+1)" v-for="(item,i) in rowsData" :key="item.title">
          <h3>{{ item.title }}</h3>
          <ul class="custom_ul">
            <!-- :class="item.selected ? 'selected' : ''" -->
            <li :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)" v-for="(ele,xi) in item.arr" :key="ele.ResultID">
              <ol class="td_name" style="width:35px">
             <p v-if="xi<=9" :class="'ball b'+Number(i)" style="margin:0">{{ ele.label }}</p>
             <template v-if="xi>9"><span :class="honglvlan[i]">{{ele.label}}</span> </template>
               
              </ol>
              <ol class="td_rate">
          
                {{ ele.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        
        
        

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao || weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span
            class="luzhu"
            :class="changelongActive == '1' ? 'selected' : ''"
            @click="setLongTab('1')"
            >露珠走势</span
          >
          <span
            class="luzhu"
            :class="changelongActive == '2' ? 'selected' : ''"
            @click="setLongTab('2')"
            >两面长龙排行</span
          >
        </div>
        <div class="rc-tab-long" v-if="changelongActive == '1'">
          <section class="luzhutitle">
            <span
              :class="qiuIndex == 1 ? 'selected' : ''"
              @click="changelttu(1)"
              >特码</span
            >
            <span
              :class="qiuIndex == 2 ? 'selected' : ''"
              @click="changelttu(2)"
              >第1球</span
            >
            <span
              :class="qiuIndex == 3 ? 'selected' : ''"
              @click="changelttu(3)"
              >第2球</span
            >
            <span
              :class="qiuIndex == 4 ? 'selected' : ''"
              @click="changelttu(4)"
              >第3球</span
            >
          </section>
          <div class="dxds_wrap">
            <section :class="ltIndex==1?'selected':''" @click="changeltIndex(1)">
              <div class="dxds_item red">
                <span> <span class="yuan">大</span></span>
                <span>{{ current_dsdx.da }}</span>
              </div>
              <div class="dxds_item blue">
                <span><span class="yuan">小</span></span>
                <span>{{ current_dsdx.xiao }}</span>
              </div>
            </section>
            <section :class="ltIndex==2?'selected':''" @click="changeltIndex(2)">
              <div class="dxds_item red">
                <span><span class="yuan">单</span></span>
                <span>{{ current_dsdx.dan }}</span>
              </div>
              <div class="dxds_item blue">
                <span> <span class="yuan">双</span></span>
                <span>{{ current_dsdx.shuang }}</span>
              </div>
            </section>
          </div>
          <div class="roadmap">
          <div class="yuying" v-if="ltIndex==1" >
            <section class="tdd" v-for="(ele,i) in current_dsdx.dxArr" :key="ele+i+'yuying'">
              <span :class="bgColor1[ele]">{{  ele}}</span>
            </section>
             
          </div>
          <div class="yuying" v-if="ltIndex==2" >
            <section class="tdd" v-for="(ele,i) in current_dsdx.dsArr" :key="ele+i+'yuying'">
              <span :class="bgColor1[ele]">{{  ele}}</span>
            </section>
             
          </div>



            <table class="ltbinfo">
            <tbody>
              <tr>
                <th>珠盘路</th>
              </tr>
            </tbody>
          </table>
            <table v-if="qiuIndex==1" colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <template v-for="(item,index) in lutu1.arr2" >
                <tr v-if="ltIndex==1" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
              <template v-for="(item,index) in lutu1.arr1" >
                <tr v-if="ltIndex==2" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
             
            </tbody>
          </table>
          <!-- ------------------------------------------------------ -->
          <table v-if="qiuIndex==2" colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <template v-for="(item,index) in lutu2.arr2" >
                <tr v-if="ltIndex==1" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
              <template v-for="(item,index) in lutu2.arr1" >
                <tr v-if="ltIndex==2" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
             
            </tbody>
          </table>
          <!-- ------------------------------------------------------ -->
          <table v-if="qiuIndex==3" colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <template v-for="(item,index) in lutu3.arr2" >
                <tr v-if="ltIndex==1" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
              <template v-for="(item,index) in lutu3.arr1" >
                <tr v-if="ltIndex==2" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
             
            </tbody>
          </table>
          <!-- ------------------------------------------------------ -->
          <table v-if="qiuIndex==4" colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <template v-for="(item,index) in lutu4.arr2" >
                <tr v-if="ltIndex==1" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
              <template v-for="(item,index) in lutu4.arr1" >
                <tr v-if="ltIndex==2" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  :class="bgColor[ele]"></span>

                </td>

              </tr>
              </template>
             
            </tbody>
          </table>
          <!-- ------------------------------------------------------ -->
          <!-- ------------------------------------- --------------------->

          </div>





        </div>
        <!-- 长龙 -->
        <div class="rc-tab-long" v-if="changelongActive == '2'">
          <div class="long-isbz">
            <span
              @click="setKaiTab('1')"
              :class="kaiActive == '1' ? 'selected' : ''"
              >连续开奖</span
            >
            <span
              @click="setKaiTab('2')"
              :class="kaiActive == '2' ? 'selected' : ''"
              >连续未开</span
            >
          </div>
          <ul class="longlist" v-if="kaiActive == '1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{ item.info }}</span>
              <b class="red">{{ item.num }}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive == '2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{ item.info }}</span>
              <b class="blue">{{ item.num }}期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'dw',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "第一球", arr: [] },
        { title: "第二球", arr: [] },
        { title: "第三球", arr: [] },
      ],
      // numArr3: [],
      // numArr2: [],
      // numArr1: [],
      honglvlan:['red','green','blue'],
      num1: 0,
      num2: 1,
      num3: 2,
      numArr: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27,
      ],
      labelArr1: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27,
      ],
      labelArr2: [
        "大",
        "单",
        "极大",
        "大单",
        "大双",
        "小",
        "双",
        "极小",
        "小单",
        "小双",
      ],
      //大      单   极大  大单  大双   小     双   极小   小单  小双
      lmIds: [2048, 2050, 2052, 2054, 2055, 2049, 2051, 2053, 2056, 2057],
      labelArr3: ["红波", "绿波", "蓝波", "豹子"],
      colorArr: ["red", "green", "blue", ""],
         // 特码录图
         lutu1: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
      },
      // 第一球录图
      lutu2: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
      },
      // 第二球录图
      lutu3: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
      },
      // 第三球录图
      lutu4: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
      },
      ltArr: [],
      current_dsdx: {
        da: 0,
        xiao: 0,
        dan: 0,
        shuang: 0,
        dsArr:[],
        dxArr:[]
      },
      tema_dsdx: {
        da: 0,
        xiao: 0,
        dan: 0,
        shuang: 0,
        dsArr:[],
        dxArr:[]
      },
      qiu1_dsdx: {
        da: 0,
        xiao: 0,
        dan: 0,
        shuang: 0,
        dsArr:[],
        dxArr:[]
      },
      qiu2_dsdx: {
        da: 0,
        xiao: 0,
        dan: 0,
        shuang: 0,
        dsArr:[],
        dxArr:[]
      },
      qiu3_dsdx: {
        da: 0,
        xiao: 0,
        dan: 0,
        shuang: 0,
        dsArr:[],
        dxArr:[]
      },
    };
  },
  computed: {
    numArr1() {
      return this.numArr.filter((num) => num != this.num2 && num != this.num3);
    },
    numArr2() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num3);
    },
    numArr3() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num2);
    },
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  watch: {
    num1: {
      immediate: true,
      handler(val) {},
    },
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                // if (ele.label == "特码包三") {
                //   obj.num = this.num1 + "," + this.num2 + "," + this.num3;
                // }
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 定位
      let oneballlabel=['0','1','2','3','4','5','6','7','8','9','大','小','单','双']
      let twoballlabel=['0','1','2','3','4','5','6','7','8','9','大','小','单','双']
      let threeballlabel=['0','1','2','3','4','5','6','7','8','9','大','小','单','双']
      
        //定位
       // 定位
       let arr4=this.filterData(resAArr, 21887, 21900, oneballlabel)
      this.rowsData[0].arr = arr4
      let arr5=this.filterData(resAArr, 21901, 21914, twoballlabel)
      this.rowsData[1].arr = arr5
      let arr6=this.filterData(resAArr, 21915, 21928, threeballlabel)
      this.rowsData[2].arr = arr6
     


        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    changeltIndex(num){
      this.ltIndex=num
    },
    changelttu(num) {
      this.qiuIndex = num;
      if (this.qiuIndex == 1) {
        this.current_dsdx = JSON.parse(JSON.stringify(this.tema_dsdx));
      } else if (this.qiuIndex == 2) {
        this.current_dsdx = JSON.parse(JSON.stringify(this.qiu1_dsdx));
      } else if (this.qiuIndex == 3) {
        this.current_dsdx = JSON.parse(JSON.stringify(this.qiu2_dsdx));
      } else if (this.qiuIndex == 4) {
        this.current_dsdx = JSON.parse(JSON.stringify(this.qiu3_dsdx));
      }
      if(this.current_dsdx.dxArr.length<66){
        let num=66-this.current_dsdx.dxArr.length
        for (let index = 0; index < num; index++) {
          this.current_dsdx.dxArr.push("");
          }

      }
      if(this.current_dsdx.dsArr.length<66){
        let num=66-this.current_dsdx.dsArr.length
        for (let index = 0; index < num; index++) {
          this.current_dsdx.dsArr.push("");
          }

      }
      console.log( this.current_dsdx,519)
    },
    getKJHis() {
      (this.tema_dsdx = {
        da: 0,
        xiao: 0,
        dan: 0,
        shuang: 0,
        dsArr:[],
        dxArr:[]
      }),
        (this.qiu1_dsdx = {
          da: 0,
          xiao: 0,
          dan: 0,
          shuang: 0,
          dsArr:[],
        dxArr:[]
        }),
        (this.qiu2_dsdx = {
          da: 0,
          xiao: 0,
          dan: 0,
          shuang: 0,
          dsArr:[],
        dxArr:[]
        }),
        (this.qiu3_dsdx = {
          da: 0,
          xiao: 0,
          dan: 0,
          shuang: 0,
          dsArr:[],
        dxArr:[]
        });
      // 特码
      this.lutu1 = {
        arr1: [[], [], [], [], [], []], //单双
        arr2: [[], [], [], [], [], []], //大小
      };
      // 第一球录图
      this.lutu2 = {
        arr1: [[], [], [], [], [], []], //单双
        arr2: [[], [], [], [], [], []], //大小
      };
      // 第二球录图
      this.lutu3 = {
        arr1: [[], [], [], [], [], []], //单双
        arr2: [[], [], [], [], [], []], //大小
      };
      // 第三球录图
      this.lutu4 = {
        arr1: [[], [], [], [], [], []], //单双
        arr2: [[], [], [], [], [], []], //大小
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 66,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          // console.log(resObj, 494);
          if (resObj.data) {
            // 特码----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              // 第一球
              arr2_ds = [[]],
              arr2_dx = [[]],
              // 第二球
              arr3_ds = [[]],
              arr3_dx = [[]],
              // 第三球
              arr4_ds = [[]],
              arr4_dx = [[]];
            let list = resObj.data;
            list.reverse();
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              element.codeArr.push(sum);
              // 特码-------------------------------------------------
              // 单双
              let q1 = element.codeArr[3];
              let ds1 = Number(q1) % 2 == 0 ? "双" : "单";
              this.tema_dsdx.dsArr.push(ds1)
              if (ds1 == "单") {
                this.tema_dsdx.dan++;
              }
              if (ds1 == "双") {
                this.tema_dsdx.shuang++;
              }
              this.initlutuData1(arr1_ds, ds1);
              // 大小
              let dx1 = Number(q1) >= 14 ? "大" : "小";
              this.tema_dsdx.dxArr.push(dx1)
              if (dx1 == "大") {
                this.tema_dsdx.da++;
              }
              if (dx1 == "小") {
                this.tema_dsdx.xiao++;
              }
              this.initlutuData1(arr1_dx, dx1);
              // 第一球----------------------------------------------
              let q2 = element.codeArr[0];
              let ds2 = Number(q2) % 2 == 0 ? "双" : "单";
              this.qiu1_dsdx.dsArr.push(ds2)
              if (ds2 == "单") {
                this.qiu1_dsdx.dan++;
              }
              if (ds2 == "双") {
                this.qiu1_dsdx.shuang++;
              }
              this.initlutuData1(arr2_ds, ds2, true);
              // 大小
              let dx2 = Number(q2) >= 5 ? "大" : "小";
              this.qiu1_dsdx.dxArr.push(dx2)
              if (dx2 == "大") {
                this.qiu1_dsdx.da++;
              }
              if (dx2 == "小") {
                this.qiu1_dsdx.xiao++;
              }
              this.initlutuData1(arr2_dx, dx2);
              // 第二球---------------------------------------------
              let q3 = element.codeArr[1];
              let ds3 = Number(q3) % 2 == 0 ? "双" : "单";
              this.qiu2_dsdx.dsArr.push(ds3)
              if (ds3 == "单") {
                this.qiu2_dsdx.dan++;
              }
              if (ds3 == "双") {
                this.qiu2_dsdx.shuang++;
              }
              this.initlutuData1(arr3_ds, ds3);
              // 大小
              let dx3 = Number(q3) >= 5 ? "大" : "小";
              this.qiu2_dsdx.dxArr.push(dx3)
              if (dx3 == "大") {
                this.qiu2_dsdx.da++;
              }
              if (dx3 == "小") {
                this.qiu2_dsdx.xiao++;
              }
              this.initlutuData1(arr3_dx, dx3);
              // 第三球-------------------------------------
              let q4 = element.codeArr[2];
              let ds4 = Number(q4) % 2 == 0 ? "双" : "单";
              this.qiu3_dsdx.dsArr.push(ds4)
              if (ds4 == "单") {
                this.qiu3_dsdx.dan++;
              }
              if (ds4 == "双") {
                this.qiu3_dsdx.shuang++;
              }
              this.initlutuData1(arr4_ds, ds4);
              // 大小
              let dx4 = Number(q4) >= 5 ? "大" : "小";
              this.qiu3_dsdx.dxArr.push(dx4)
              if (dx4 == "大") {
                this.qiu3_dsdx.da++;
              }
              if (dx4 == "小") {
                this.qiu3_dsdx.xiao++;
              }
              this.initlutuData1(arr4_dx, dx4);
            });
            //  特码 单双大小
            this.buquanArr2(arr1_ds, this.lutu1.arr1);
            this.buquanArr2(arr1_dx, this.lutu1.arr2);
            //第一球 单双大小
            this.buquanArr2(arr2_ds, this.lutu2.arr1);
            this.buquanArr2(arr2_dx, this.lutu2.arr2);
            // 第二球
            this.buquanArr2(arr3_ds, this.lutu3.arr1);
            this.buquanArr2(arr3_dx, this.lutu3.arr2);
            // 第三球
            this.buquanArr2(arr4_ds, this.lutu4.arr1);
            this.buquanArr2(arr4_dx, this.lutu4.arr2);
            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];

            //  -------------------------------------------

            //  -------------------------------------------

            //  -------------------------------------------
            this.changelttu(this.qiuIndex);
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.pcddopen .ball:after {
   
    content: "";
  
}
.listmain.game-pcdddw .cZ3{
  width: calc((100% - 8px) / 3);
}
.listmain.game-pcdddw .cZ1,
.listmain.game-pcdddw .cZ2{
  margin-right: 2px;
  width: calc((100% - 8px) / 3);
}
 
.listmain > .data ul.custom_ul li {
  height: 30px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
 

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data {
  width: 33.333%;
 
}
.listmain {
  display: flex;
}
</style>
